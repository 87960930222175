import React from 'react'
import { Box, Typography, SvgIcon, Link } from '@lib/ui/core'
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { useStoreActions } from "easy-peasy"

import { ReactComponent as Logo } from '@assets/icons/logo-icon.svg'
import { ReactComponent as AppStore } from '@assets/icons/app-store.svg'
import { ReactComponent as GooglePlay } from '@assets/icons/google-play.svg'


const Complete = () => {
  const fetchSearchResult = useStoreActions(({ offerSearch }) => offerSearch.fetchSearchResult)
  return (
    <Layout>
      <Container>
        <Box pt={7.5}>
          <Box mb={2.5} textAlign="center">
            <SvgIcon component={Logo} fontSize="4.5rem" />
          </Box>
          <Box mb={1}>
            <Typography variant="h2" align="center">
              立即下載 JetsoBee 手機 App
            </Typography>
          </Box>
          <Box mb={2.5} textAlign="center">
            <Typography variant="body1">
              感謝你登記本網信用卡通訊。現誠邀你下載 JetsoBee 手機 App，讓你更快更簡單取得最適合你的優惠！
            </Typography>
          </Box>
          <Box display="flex" justifyContent="center" mb={4}>
            <Link color="initial" href="https://apps.apple.com/hk/app/jetsobee著數蜂子-個人化信用卡優惠平台/id1499098955?l">
              <Box mr={1} component="ButtonBase" height="100%" width={{ xs: "7rem", sm: "8.25rem" }} clone>
                <AppStore />
              </Box>
            </Link>
            <Link color="initial" href="https://play.google.com/store/apps/details?id=com.jetso.smartspending">
              <Box component="ButtonBase" height="100%" width={{ xs: "8.5rem", sm: "10rem" }} clone>
                <GooglePlay />
              </Box>
            </Link>
          </Box>
        </Box>
        <Typography style={{ cursor: 'pointer', "text-decoration": "underline" }} variant="h4" align="center" onClick={fetchSearchResult} color="secondary">
          繼續搜尋你的優惠
        </Typography>
      </Container>
    </Layout>
  )
}

export default Complete
